<template>
  <div>
    <!-- show navbar only if user is logged in -->
    <Navbar />
    <v-container class="dashboard" id="dashboard">
      <h1>Performance</h1>
      <v-container class="px-lg-12">
        <v-card class="mb-3">
          <v-card-title>
            <h3 class="">Performance and Data of the Bot</h3>
          </v-card-title>
          <v-card-text>
            <v-card flat>
              <v-card-title>{{ bot.title }}</v-card-title>
              <v-card-text>
                <!-- Performance Graph -->
                <canvas
                  ref="perf_canvas"
                  width="450"
                  height="200"
                  name="perfChart"
                ></canvas>
                <v-divider class="my-3 mx-4"></v-divider>
                <!-- Performance Data -->
                <v-data-table
                  dense
                  :sort-by="sort_by"
                  :sort-desc="sort_desc"
                  :headers="headers"
                  :items="perf_data"
                  :items-per-page="25"
                  class="elevation-1 mt-5"
                >
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Chart from "chart.js/auto";

export default {
  name: "Home",
  components: {
    Navbar,
  },
  data() {
    return {
      tab: null,
      loading: false,
      sort_by: "date",
      sort_desc: true,
      bot: {
        title: "Bot Title",
      },
      headers: [
        { text: "Datum", value: "date" },
        { text: "Balance", value: "balance" },
        { text: "Benchmark", value: "benchmark" },
        { text: "Asset Price", value: "asset_price" },
      ],
      perf_data: [],
    };
  },
  methods: {
    async fetch_bot_performance(userId) {
      const token = this.$store.getters["user/getTokenValue"];
      const botId = this.$route.params["botId"];
      try {
        let request = await fetch(
          `${process.env.VUE_APP_PERFORMANCE_URL}/${userId}/${botId}`,
          {
            method: "GET",
            withCredentials: true,
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
            // mode: "no-cors"
          }
        );
        if (request.status === 200 || request.status === 401) {
          return await this.formatData(request);
          // return request.text();
        } else {
          console.log("Could not fetch historical bot data");
          //await this.formatData(request);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async formatData(request) {
      const text = await request.text();
      const data = await JSON.parse(text);
      return data;
    },
    async fetch_bot(userId) {
      const token = this.$store.getters["user/getTokenValue"];
      const botId = this.$route.params["botId"];
      try {
        let request = await fetch(
          `${process.env.VUE_APP_SINGLE_BOT_URL}${userId}/${botId}`,
          {
            method: "GET",
            withCredentials: true,
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        if (request.status === 200 || request.status === 401) {
          return await this.formatData(request);
        } else {
          console.log("Could not fetch historical bot data");
        }
      } catch (err) {
        console.log(err);
      }
    },
    //prints a single chart
    printChart(ref) {
      new Chart(ref, {
        data: {
          labels: this.printData.date,
          datasets: [
            {
              type: "line",
              label: "Balance",
              data: this.printData.performance,
              backgroundColor: ["rgba(128, 128, 128, 0.3)"],
              borderColor: ["rgba(128, 128, 128, 0.75)"],
              borderWidth: 2,
              radius: 0,
              pointHitRadius: 2,
            },
            {
              type: "line",
              label: "Benchmark",
              data: this.printData.benchmark,
              backgroundColor: ["rgba(12, 53, 175, 0.3)"],
              borderColor: ["rgba(12, 53, 175, 0.75)"],
              borderWidth: 2,
              radius: 0,
              pointHitRadius: 2,
            },
          ],
        },
        options: {
          responsive: true,
          radius: 0,
          scales: {
            y: {
              title: {
                display: true,
                text: "Preis",
              },
              beginAtZero: false,
            },
            x: {
              title: {
                display: true,
                text: "Zeitinterval",
              },
              ticks: {
                stepSize: 10,
                callback: function (val, index) {
                  // Hide the label of every 8th dataset
                  return index % 2 === 0 ? this.getLabelForValue(val) : "";
                },
                color: "black",
                maxRotation: 45,
              },
            },
          },
        },
      });
    },
  },
  computed: {
    //structuring the data for printing it as chart
    printData: function () {
      let bots_dates = [];
      let bots_performance = [];
      let bots_benchmark = [];
      let bots_asset_price = [];
      // let length = this.perf_data.length;
      this.perf_data.forEach((row) => {
        bots_dates.push(row.date);
        bots_benchmark.push(row.benchmark);
        bots_performance.push(row.balance);
        bots_asset_price.push(row.asset_price);
      });
      const botData = {
        date: bots_dates,
        benchmark: bots_benchmark,
        performance: bots_performance,
        asset_price: bots_asset_price,
      };
      return botData;
    },
  },
  created: async function () {
    //fetching the bot
    const bot = await this.fetch_bot(this.$store.state.user.user.id);
    this.bot = bot.bot;
    //fetching the performance
    const hist_perf = await this.fetch_bot_performance(
      this.$store.state.user.user.id
    );
    this.perf_data = hist_perf.data;
    //printing the graph
    this.printChart(this.$refs.perf_canvas);
  },
};
</script>
<style>
.dashboard {
  scroll-behavior: smooth;
}
</style>